<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card class="dialog">
        <v-form
          ref="form"
        >
          <v-text-field
            :disabled="!hidden"
            v-model="email"
            :rules="emailRules"
            label="Введите Ваш E-mail"
            required
            color="#4caf50"
          ></v-text-field>

          <div class="btn-block_" v-if="hidden">
            <v-btn class="btn" small @click="cancel()">отмена</v-btn>
            <v-btn class="btn" small @click="next()">далее</v-btn>
          </div>

          <div class="review-form-block" v-if="!hidden">
            <v-text-field
              :disabled="this.getCheckEmail().username ? true : false"
              v-model="name"
              :counter="50"
              :rules="nameRules"
              label="Ваше имя"
              required
              color="#4caf50"
            ></v-text-field>
            
            <div class="rating">
              <v-rating
                v-model="rating"
                background-color="#ffa800"
                color="#ffa800"
                dense
                size=25
              ></v-rating>
              <span class="rs1" v-if="rating == 0" style="color: red">Поставьте оценку</span>
              <span class="rs2" v-if="rating != 0" style="color: black">{{rating}} из 5</span>
            </div>

            <div class="textarea">
              <v-textarea
                v-model="textarea"
                :rules="textareaRules"
                outlined
                name="input-7-4"
                label="Ваш отзыв"
                color="#4caf50"
              ></v-textarea>
            </div>

            <div class="btn-block">
              <v-spacer></v-spacer>
              <v-btn
                small
                class="btn"
                @click="cancel()"
              >
                отмена
              </v-btn>
              <v-btn
                small
                class="btn"
                @click="send"
              >
                отправить
              </v-btn>
            </div>
          </div>

        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    loadingWindow: Function
  },
  data:() => ({
    dialog: false,
    hidden: true,
    rating: 0,
    email: '',
    emailRules: [
      v => !!v || 'Обязательное поле',
      v => /.+@.+\..+/.test(v) || 'Введите корректный E-mail',
    ],
    name: '',
    nameRules: [
      v => !!v || 'Обязательное поле',
      v => (v && v.length <= 50) || 'Введите не более 50 символов',
    ],
    textarea: '',
    textareaRules: [
      v => !!v || 'Обязательное поле'
    ],
  }),
  methods: {
    ...mapActions(['checkEmail', 'reviewInsert']),
    ...mapGetters(['getCheckEmail']),
    date: () => {
      const date = new Date()
      let dd = date.getDate();
      if (dd < 10) dd = '0' + dd;
      let mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;
      let yy = date.getFullYear();
      return `${yy}-${mm}-${dd}`;
    },
    time: () => {
      const date = new Date()
      const options = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }
      const formater = new Intl.DateTimeFormat('ru-RU', options)
      return formater.format(date)
    },
    open() {
      this.dialog = true
    },
    async next() {
      if (this.$refs.form.validate()) {
        this.loadingWindow(true)
        await this.checkEmail({email: this.email.trim()})
        if (!this.getCheckEmail().username) {
          this.loadingWindow(false)
          this.hidden = false
        } else {
          this.email = this.getCheckEmail().email
          this.name = this.getCheckEmail().username
          this.loadingWindow(false)
          this.hidden = false
        }
      }
    },
    async send () {
      if (this.$refs.form.validate()) {
        if(this.rating != 0) {
          this.loadingWindow(true)
          await this.reviewInsert({
            username: this.name.trim(),
            email: this.email.trim(),
            review: this.textarea,
            rating: this.rating,
            date: `${this.date()} ${this.time()}`
          })
          this.loadingWindow(false)
          this.cancel()
          this.dialog = false
        }
      }
    },
    cancel() {
      this.$refs.form.reset()
      this.rating = 0
      this.hidden = true
      this.dialog = false
    }
  },
  created() {
    this.$emit('dialog', this.open)
  }
}
</script>

<style lang="scss" scoped>
  .dialog {
    padding: 15px;
  }
  .btn-block {
    text-align: center;
    border-top: 1px solid #C0C0C0;
    padding-top: 15px;
  }
  .btn-block_ {
    text-align: center;
    padding-top: 15px;
  }
  .btn {
    margin: 0 5px;
  }
  .textarea {
    margin-top: 19px;
  }
  .rating {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span {
      margin: 3px 0 0 10px;
    }
  }
</style>
