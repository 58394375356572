<template>
  <div class="reviews-wrapper">
    <div :class="`review-block-wrapper ${review.top}`">
      <div class="app-container">
        <div class="review-block grid">
        <div><span>{{`средний рейтинг ${generalRating ? generalRating : '--'} / 5`}}</span></div>
          <div>
            <v-rating
              :value="__generalRating"
              background-color="#4caf50"
              color="#4caf50"
              dense
              readonly
            ></v-rating>
          </div>
          <div>
            <v-btn
              class="mx-2"
              small
              @click="dialog"
            >оставить отзыв</v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="app-container">
      <div class="review-item-wrap" v-for="(item, i) in getReviews.reviews" :key="i">
      
        <div class="review-item">
          <div class="rev-u" v-if="!mobile">
            <v-icon
            >mdi-account-circle</v-icon>
            <span>{{item.username}}</span>
            <span style="color: grey">отзывов: {{ getReviews.email[`${item.email}`] }}</span>
          </div>
          <div class="rev-b">
            <div class="rev-u" v-if="mobile">
              <v-icon
              >mdi-account-circle</v-icon>
              <span>{{item.username}}</span>
              <span style="color: grey">отзывов: {{ getReviews.email[`${item.email}`] }}</span>
            </div>
            <div class="rb" v-if="!mobile"></div>
            <div class="ri-item">
              <div>
                <v-rating
                  :value="item.rating"
                  background-color="#ffa800"
                  color="#ffa800"
                  readonly
                  dense
                  size=20
                ></v-rating>
              </div>
              <div>
                {{item.date | date('smalldt')}}
              </div>
            </div>
            <div class="ri-item" v-html="item.review.replace(/\n/g, '<br/>')"></div>
          </div>
        </div>

        <div class="review-response-wrapper">
          <div class="review-response" v-if="item.res_response != null">
            <div class="rr-u" v-if="!mobile">
              <v-icon
                color="#4caf50"
              >mdi-account-circle</v-icon>
              <span>zelsvet.by</span> 
              <br>
              <span>Администратор</span>
            </div>
            <div class="rr-b">
              <div class="rr-u" v-if="mobile">
                <v-icon
                  size=70
                  color="#4caf50"
                >mdi-account-circle</v-icon>
                <span>zelsvet.by</span> 
                <br>
                <span>Администратор</span>
              </div>
              <div class="rb" v-if="!mobile"></div>
              <div class="rr-item">{{item.res_date | date('smalldt')}}</div>
              <div 
                class="rr-item" 
                v-html="`<strong>${item.username}</strong>, ${item.res_response.replace(/\n/g, '<br/>')}`"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="review-next-btn">
      <v-btn
        v-if="getReviews.arrLength > end"
        @click="next()"
      >показать еще 10 отзывов</v-btn>
    </div>
    <ReviewForm @dialog="dialog=$event" :loadingWindow="loadingWindow"/>
  </div>
</template>

<script>
import ReviewForm from '@/components/ReviewForm.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    review: Object,
    loadingWindow: Function
  },
  components: {ReviewForm},
  data: () => ({
    dialog: Function,
    start: 0,
    end: 10,
    mobile: false
  }),
  methods: {
    ...mapActions(['reviews', 'host']),
    async next() {
      this.end += 10
      this.loadingWindow(true)
      await this.reviews({start: this.start, end: this.end})
      this.loadingWindow(false)
    }
  },
  computed: {
    ...mapGetters(['getReviews']),
    generalRating() {
      return (this.getReviews.ratingSum / this.getReviews.arrLength).toFixed(1)
    },
    __generalRating() {
      return Math.round(this.getReviews.ratingSum / this.getReviews.arrLength)
    }
  },
  async mounted() {
    window.scrollTo({top: 0})
    await this.host()
    this.loadingWindow(true)
    await this.reviews({start: this.start, end: this.end})
    this.loadingWindow(false)
    window.innerWidth <= 521 ? this.mobile = true : this.mobile = false
    window.onresize = () => {
      window.innerWidth <= 521 ? this.mobile = true : this.mobile = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .reviews-wrapper {
    padding-top: 155px;
    position: relative;
    .review-block-wrapper {
      position: fixed;
      padding: 5px 10px;
      background-color: 	#DCDCDC;
      border: 1px solid #D3D3D3;
      border-radius: 3px;
      width: 100%;
      z-index: 1;
    }
    .review-block {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      grid-gap: 10px;
      align-items: center;
      justify-items: center;
      max-width: 790px;
      margin: 0 auto;
      div {
        &:nth-child(1) {
          span {
            font-weight: bold;
          }
        }
      }
    }
    .review-item {
      display: grid;
      grid-template-columns: 1fr 9fr;
      grid-gap: 10px;
      max-width: 1150px;
      margin: 0 auto 25px;
      align-items: center;
      justify-items: left;
      .rev-u {
        display: grid;
        justify-content: center;
        align-content: center;
        padding: 0 15px;
        i {
          font-size: 70px;
        }
        span {
          text-align: center;
        }
      }
      .rev-b {
        box-shadow: 0px 0px 3px 1px #979797;
        border-radius: 3px;
        padding: 10px 25px;
        position: relative;
        .ri-item {
          &:nth-child(2) {
            border-bottom: 1px solid #c0c0c0;
            display: flex;
            flex-wrap: wrap;
            padding: 10px 0;
            div {
              display: flex;
              &:nth-child(1) {
                padding: 0 5px 1px 0;
              }
            }
          }
          &:nth-child(3) {
            padding: 10px 0;
          }
        }
      }
    }
    .review-response-wrapper {
      display: grid;
      justify-content: end;
      .review-response {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        margin-bottom: 25px;
        align-items: center;
        justify-items: end;
        .rr-u {
          display: grid;
          justify-content: center;
          align-content: center;
          font-weight: bold;
          padding: 0 15px;
          i {
            font-size: 70px;
          }
          span {
            text-align: center;
          }
        }
        .rr-b {
          box-shadow: 0px 0px 3px 1px #979797;
          border-radius: 3px;
          padding: 10px 25px;
          position: relative;
          .rr-item {
            &:nth-child(2) {
              border-bottom: 1px solid #c0c0c0;
              padding: 10px 0;
            }
            &:nth-child(3) {
              padding: 10px 0;
            }
          }
        }
      }
    }
  }
  .rb {
    box-shadow: 4px 4px 3px -3px #979797;
    height: 30px;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%) rotate(135deg);
    background-color: #fff;
  }
  .review-next-btn {
    margin: 0 auto;
    max-width: 500px;
    text-align: center;
  }
  .menu-fixed {
    top: 79px;
    animation-name: menu_show;
    animation-duration: .3s;
  }
  @keyframes menu_show {
    0% {
      top: 0px;
    }
    100% {
      top: 79px;
    }
  }
  .menu-noFixed {
    top: 0px;
    animation-name: menu_hide;
    animation-duration: .3s;
  }
  @keyframes menu_hide {
    0% {
      top: 79px;
    }
    100% {
      top: 0px;
    }
  }

  @media only screen and (max-width: 870px) {
    .menu-fixed {
      top: 50px;
    }
    @keyframes menu_show {
      0% {
        top: 0px;
      }
      100% {
        top: 50px;
      }
    }
    @keyframes menu_hide {
      0% {
        top: 50px;
      }
      100% {
        top: 0px;
      }
    }
  }
  @media only screen and (max-width: 521px) {
    .rev-u {
      i {
        font-size: 49px !important;
      }
    }
    .rr-u {
      i {
        font-size: 49px !important;
      }
    }
    .reviews-wrapper {
      .review-item {
        display: block;
      }
    }
    .reviews-wrapper {
      .review-response-wrapper {
        .review-response {
          display: block;
        }
      }
    }
  }
  @media only screen and (max-width: 361px) {
    .reviews-wrapper {
      padding-top: 195px;
    }
  }
</style>
